import {Panel} from 'primereact/panel';
import {Button} from 'primereact/button';
import {InputNumber} from 'primereact/inputnumber';
import {Dialog} from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import {useEffect, useState} from "react";
import toRupiah from "@develoka/angka-rupiah-js";

function HargaModalCard({ onChangeValue }) {
    const [modalPriceType, setModalPriceType] = useState("manual")
    const [showAutomaticSetting, setShowAutomaticSetting] = useState(false)
    const [rows, setRows] = useState([]);
    const [basicPrice, setBasicPrice] = useState('');
    const [finalModalPrice, setFinalModalPrice] = useState(0);
    const [tempHargaModal, setTempHargaModal] = useState('');

    useEffect(() => {
        let temp = basicPrice;
        rows.forEach(x => {
            if (x.type === 1) {
                temp = (temp * x.buy) / (x.free + x.buy);
            } else {
                temp -= temp * (x.discount / 100);
            }
        })

        setFinalModalPrice(temp || basicPrice);
    }, [basicPrice, rows]);

    const onClickAutomaticSetting = () => {
        setShowAutomaticSetting(true)
    }

    const onClickManualSetting = () => {
        setTempHargaModal('')
        setModalPriceType('manual')
    }

    const onSave = () => {
        onChangeValue(finalModalPrice);
        setTempHargaModal(finalModalPrice)
        setModalPriceType('automatic')
        setShowAutomaticSetting(false)
    }

    const addDeduct = (type) => {
        const tempRows = [...rows];
        let newIndex = 0;
        let newItem = null;
        if (rows.length > 0) {
            newIndex = rows.length;

            if (type === 2) {
                let bonusItemIndex = rows.findIndex(x => x.type === 1);
                if (bonusItemIndex !== -1) {
                    newIndex = bonusItemIndex - 1;
                }
            }
        }
        if (type === 1) { // bonus item
            newItem = {
                type: 1,
                buy: 0,
                free: 0
            };
        } else {
            newItem = {
                type: 2,
                discount: 0
            };
        }
        tempRows.splice(newIndex, 0, newItem)
        setRows(tempRows)
    }

    const onDelete = (index) => {
        const tempRows = [...rows];
        tempRows.splice(index, 1)
        setRows(tempRows)
    }

    const onChangeInput = (index, values) => {
        let row = {...rows[index]};

        if (row.type === 1) {
            const [buy, free] = values;
            row.buy = buy;
            row.free = free;
        } else if (row.type === 2) {
            row.discount = values;
        }

        let temp = [...rows];
        temp[index] = {
            ...row
        }
        setRows(temp)
    }

    const onResettingAutomaticPrice = () => {
        setShowAutomaticSetting(true);
    }

    return (
      <>
          <Dialog header="Hitung Harga Modal Otomatis" visible={showAutomaticSetting}
                  onHide={() => setShowAutomaticSetting(!showAutomaticSetting)}
                  style={{width: '50vw'}} breakpoints={{'960px': '75vw', '641px': '100vw'}}>
              <label className="font-bold block mb-2">Harga Dasar</label>
              <div className="p-inputgroup flex-1">
                  <span className="p-inputgroup-addon">Rp</span>
                  <InputNumber value={basicPrice} onChange={x => setBasicPrice(x.value)} locale="id-ID"/>
              </div>
              <Divider/>
              <div className="flex justify-content-between align-items-center flex-column md:flex-row">
                  <h3 className="font-bold block mb-2 mt-0 md:my-0">Diskon Margin & Bonus</h3>
                  <div className="">
                      {
                          rows.findIndex(x => x.type === 1) === -1 && (
                              <Button size="small" className="w-auto mr-2" label="Tambah Bonus" onClick={() => addDeduct(1)}/>
                          )
                      }
                      <Button size="small" className="w-auto" label="Tambah Diskon" onClick={() => addDeduct(2)} />
                  </div>
              </div>
              <Divider/>
              {
                  rows.length > 0 && (
                      <>
                          {
                              rows.map((row, i) => (
                                  row.type === 1 ? (
                                      <div className="grid" key={i}>
                                          <div className="col-5">
                                              <div className="p-inputgroup flex-1">
                                                  <span className="p-inputgroup-addon">Beli</span>
                                                  <InputNumber value={row.buy} onChange={(p) => onChangeInput(i, [p.value, row.free])} locale="id-ID"/>
                                              </div>
                                          </div>
                                          <div className="col-5">
                                              <div className="p-inputgroup flex-1">
                                                  <span className="p-inputgroup-addon">Gratis</span>
                                                  <InputNumber value={row.free} onChange={(p) => onChangeInput(i, [row.buy, p.value])} locale="id-ID"/>
                                              </div>
                                          </div>
                                          <div className="col-2">
                                              <Button onClick={() => onDelete(i)} className="w-full justify-content-center" severity="danger" label={<i
                                                  className="pi pi-trash"/>}/>
                                          </div>
                                      </div>
                                  ) : (
                                      <div className="grid" key={i}>
                                          <div className="col-10">
                                              <div className="p-inputgroup flex-1">
                                                  <span className="p-inputgroup-addon">Disc</span>
                                                  <InputNumber value={row.discount} onChange={(p) => onChangeInput(i, p.value)} locale="id-ID"/>
                                                  <span className="p-inputgroup-addon">%</span>
                                              </div>
                                          </div>
                                          <div className="col-2">
                                              <Button onClick={() => onDelete(i)} className="w-full justify-content-center" severity="danger" label={<i
                                                  className="pi pi-trash"/>}/>
                                          </div>
                                      </div>
                                  )
                              ))
                          }
                          <Divider/>
                      </>
                  )
              }
              <h3 className="font-bold block my-0">Harga Modal Akhir</h3>
              <div className="mt-2">
                  {toRupiah(finalModalPrice || 0, {formal: false, floatingPoint: 0})}
              </div>

              <Button size="small" className="w-full mt-4" label="Simpan" disabled={!finalModalPrice} onClick={onSave}/>
          </Dialog>
          <Panel header="Harga Modal" className="mb-3">
          <div className="flex justify-content-between align-items-start md:align-items-center mb-3 flex-column md:flex-row">
                  <label className="font-bold block">Input harga modal atau gunakan tombol hitung otomatis</label>

                  {
                      modalPriceType === "manual" ? (
                          <Button size="small" className="w-auto mt-2 md:mt-0" label="Hitung Otomatis" onClick={onClickAutomaticSetting} />
                      ) : (
                          <div className="flex mt-2 md:mt-0">
                              <Button size="small" className="w-auto mr-2" severity="success" label="Ganti Perhitungan" onClick={onResettingAutomaticPrice}/>
                              <Button size="small" className="w-auto" label="Set Harga Manual" onClick={onClickManualSetting}/>
                          </div>
                      )
                  }
              </div>
              <div className="p-inputgroup flex-1">
                  <span className="p-inputgroup-addon">Rp</span>
                  <InputNumber disabled={modalPriceType === 'automatic'} value={tempHargaModal} onChange={x => onChangeValue(x.value)} locale="id-ID"/>
              </div>
          </Panel>
      </>
  )
}


export default HargaModalCard;
